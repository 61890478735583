@import "_mixins.scss";

$pale-grey: rgb(244, 246, 255);
$sapphire: rgb(38, 78, 167);
$marine: rgb(8, 34, 105);
$marine-focus: rgba(8, 34, 105, 0.65);

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.main-search {
  input {
    font-size: 0.75rem;
    font-weight: 400;
    width: 300px;
  }
}

.input-group-login {
  ::placeholder {
    color: white;
  }

  -webkit-text-fill-color: #fff !important;

  input {
    background: transparent;
    color: white;
    border: 0;
    box-shadow: none;
    outline: 0;
    border-bottom: 1px solid white;
    border-radius: 0;
    padding-left: 0;
    padding-bottom: 0.4rem;

    &:focus {
      background: transparent;
      color: white;
      border-bottom: 1px solid white;
    }
  }
}

.borderless-input {
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  border-bottom-style: groove;
}

.input-group {
  background-color: $pale-grey;
  border-radius: 0.75rem !important;
  border-radius: 8px;
  line-height: 52px;

  span {
    border: none;
    background-color: $pale-grey;
  }

  img {
    margin-left: 10px;
    color: $pale-grey;
    width: 20px;
    height: 20px;
  }

  input {
    border: none;
    width: 80%;
    background-color: $pale-grey;
    outline: none;
    color: $sapphire;
    font-family: "Poppins", sans-serif;

    &::placeholder {
      border: none;
      outline: none;
      color: $sapphire;
      font-size: 1rem !important;
    }

    &:focus {
      border: none;
      outline: none;
      color: $sapphire;
    }
  }

  &.search {
    background: none !important;

    input {
      font-size: 0.85rem !important;
      border-bottom: 1px solid #e3e4e8 !important;
      padding-left: 2rem;
      position: relative;
      background-position: left 40%;
      background-repeat: no-repeat;
      opacity: 0.5;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.349' height='20.35' viewBox='0 0 20.349 20.35'%3E%3Cpath id='Path_6495' data-name='Path 6495' d='M1155.847,637.391l-4.251-4.251a8.962,8.962,0,1,0-1.17,1.17l4.251,4.251a.827.827,0,0,0,1.17-1.17Zm-18.452-9.976a7.307,7.307,0,1,1,7.307,7.307A7.306,7.306,0,0,1,1137.4,627.415Z' transform='translate(-1135.74 -618.453)' fill='%23264ea7'/%3E%3C/svg%3E%0A");

      &:active,
      &:focus {
        border-bottom: 1px solid #f5f7ff !important;
        opacity: 1;
      }
    }
  }
}

.form-check-input[type="radio"] {
  border-radius: 50% !important;
  background-image: none !important;
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  &:checked {
    background-color: $color-accent;
    border-color: $color-accent;

    &:after {
      width: 1rem;
      height: 1rem;
      position: absolute;
      content: " ";
      z-index: 9;
      background: #fff;
      border-radius: 50%;
    }
  }
}

.form-search {
  position: relative;
  top: 40px;
  left: 30px;
  transform: translate(-35px, -35px);
  transition: all 1s;
  width: 100%;
  height: 50px;
  background: transparent;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 5px;

  @include tablet {
    margin-bottom: 0;
    width: 100px;
  }

  @include desktop {
    margin-bottom: 0;
    width: 200px;
  }



  // &:hover {
  //   width: 200px;
  //   cursor: pointer;

  //   input {
  //     display: block;
  //   }
  // }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 42.5px;
    line-height: 30px;
    outline: 0;
    border: 0;
    // display: none;
    border-radius: 20px;
    padding: 0 20px;
    //
    font-size: 0.75rem;
    font-weight: bolder;
    background-color: $color-pale-grey;
    color: $color-sapphire;
    opacity: 0.6;

    &:active,
    &:focus {
      color: $marine;
      background-color: $color-pale-grey;
      opacity: 1;
    }
  }

  i {
    background-color: $color-pale-grey;
    padding: 7px;
    box-sizing: border-box;
    padding: 5px;
    width: 42.5px;
    height: 42.5px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 25%;
    text-align: center;
    font-size: 1.2em;
    transition: all 1s;
  }
}

.user-input {
  display: flex;
  flex-direction: column;

  label {
    margin: 0;
    color: $marine;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }

  select,
  textarea,
  input {
    color: $dark-slate-blue;
    font-size: 17px;
    font-family: "Poppins", sans-serif;
    border: none;
    border-bottom: inset;
    border-bottom-color: $white-hover;
    line-height: 27px;

    &:focus {
      outline: none;
      border-bottom: inset;
      border-bottom-color: $marine-focus;
    }
  }
}